<template>
  <b-container fluid class="afiliados">
    <div class="mt-4">
      <p class="total-vendas info">⚡️ {{ itens }} {{ $t('seller.affiliation.meus_afiliados.text_1664') }}</p>
    </div>
    <b-row class="header-table">
      <div class="flex-sale">
        <div class="d-flex align-items-center">
          <b-tooltip
            target="filtro-1"
            :title="$t('seller.affiliation.meus_afiliados.text_1665')"
            placement="topright"
          />
          <b-button
            id="filtro-1"
            class="btn-table"
            @click="openFilters"
            :title="$t('seller.affiliation.meus_afiliados.text_1666')"
            ><img src="@/assets/img/icons/filtro.svg"
          /></b-button>
        </div>

        <div class="d-flex align-items-center flex-acoes">
          <div class="datas">
            <date-range-picker
              ref="picker"
              opens="right"
              :locale-data="localeDate"
              :showWeekNumbers="false"
              :showDropdowns="true"
              :autoApply="false"
              :ranges="dateRanges"
              @update="fetchAll"
              v-model="dateRange"
            >
              <template
                v-if="dateRange.startDate && dateRange.endDate"
                v-slot:input="picker"
                style="min-width: 350px"
              >
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
              </template>
              <template
                v-else
                v-slot:input
                class="btn-table"
                :title="$t('seller.affiliation.meus_afiliados.text_1667')"
              >
                {{ $t('seller.affiliation.meus_afiliados.text_1668') }}
              </template>
            </date-range-picker>
          </div>

          <div class="filtro-produto" v-if="name_product">
            <p>
              {{ $t('seller.affiliation.meus_afiliados.text_1669') }} {{ name_product }}.
              <a href="#" @click.prevent="removeFilterProduct">
                {{ $t('seller.affiliation.meus_afiliados.text_1670') }}
              </a>
            </p>
          </div>
        </div>
      </div>

      <!-- <b-col cols="4">
        <a
          href="#"
          class="enviar-msg"
          v-if="item_check.length"
          data-anima="top"
        >
          <img src="@/assets/img/icons/mensagem.svg" />
          Enviar Mensagem ao Afiliado</a
        >
      </b-col> -->
      <!-- <b-col cols="4" id="desabilita">
        <a
          v-if="filter.products.length"
          href="#"
          class="enviar-msg"
          :disabled="!item_check.length"
          :class="{ disabled: !item_check.length }"
          @click.prevent="desabilitarAfiliado(true)"
        >
          <img src="@/assets/img/icons/desabilitar.svg" />
          Desabilitar Afiliados selecionados</a
        >
        <b-tooltip
          v-if="!item_check.length"
          target="desabilita"
          title="Selecione o afiliado"
        />
      </b-col> -->

      <b-col cols="12" md="4" class="container-pesquisa mt-3 mt-md-0">
        <div class="inputSearch">
          <img src="@/assets/img/icons/search.svg" class="Pointer search" />
          <input
            type="text"
            :placeholder="$t('seller.affiliation.meus_afiliados.text_1671')"
            v-on:input="debounce"
            v-model="search"
            class="input-busca"
          />
        </div>
      </b-col>
    </b-row>
    <!-- <a href="#" class="select-all" v-if="item_check2.length" data-anima="top"
      >Selecionar todos os Afiliados deste produto [450]</a
    > -->
    <!-- HEADER -->
    <b-row class="Table-header mt-1">
      <b-col cols="0" class="d-none d-md-block" v-if="false == true">
        <b-form-checkbox-group
          id="checkbox-group-all"
          class="input-check"
          name="flavour-all"
          v-model="item_check2"
          @change="clickCheckAll"
        >
          <b-form-checkbox value="xx"></b-form-checkbox>
        </b-form-checkbox-group>
      </b-col>
      <b-col
        cols="1"
        @click="changeAsc('id')"
        class="d-none d-md-block pointer mr-5"
      >
        {{ $t('seller.affiliation.meus_afiliados.text_1672') }}
        <svg
          :class="{
            ascendente: tableChange.id === 'asc',
            descendente: tableChange.id === 'desc1',
          }"
          class="asc-desc"
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.27656 0.166504C0.849387 0.166504 0.618932 0.667563 0.896935 0.9919L3.80982 4.39027C4.00937 4.62308 4.36953 4.62308 4.56908 4.39027L7.48197 0.991899C7.75997 0.667563 7.52952 0.166504 7.10234 0.166504H1.27656Z"
            fill="#002363"
          />
        </svg>
      </b-col>

      <b-col
        cols="4"
        md="1"
        @click="changeAsc('created_at')"
        class="d-none d-md-block pointer mr-4"
      >
        {{ $t('seller.affiliation.meus_afiliados.text_1673') }}
        <svg
          :class="{
            ascendente: tableChange.created_at === 'asc',
            descendente: tableChange.created_at === 'desc1',
          }"
          class="asc-desc"
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.27656 0.166504C0.849387 0.166504 0.618932 0.667563 0.896935 0.9919L3.80982 4.39027C4.00937 4.62308 4.36953 4.62308 4.56908 4.39027L7.48197 0.991899C7.75997 0.667563 7.52952 0.166504 7.10234 0.166504H1.27656Z"
            fill="#002363"
          />
        </svg>
      </b-col>

      <b-col cols="3" @click="changeAsc('affiliate')" md="2" class="pointer">
        {{ $t('seller.affiliation.meus_afiliados.text_1674') }}
        <svg
          :class="{
            ascendente: tableChange.affiliate === 'asc',
            descendente: tableChange.affiliate === 'desc1',
          }"
          class="asc-desc"
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.27656 0.166504C0.849387 0.166504 0.618932 0.667563 0.896935 0.9919L3.80982 4.39027C4.00937 4.62308 4.36953 4.62308 4.56908 4.39027L7.48197 0.991899C7.75997 0.667563 7.52952 0.166504 7.10234 0.166504H1.27656Z"
            fill="#002363"
          />
        </svg>
      </b-col>
      <b-col cols="5" md="2" class="d-none d-md-block"> {{ $t('seller.affiliation.meus_afiliados.text_1675') }} </b-col>
      <b-col cols="3" md="2" class="d-none d-md-block"> {{ $t('seller.affiliation.meus_afiliados.text_1676') }} </b-col>
      <b-col
        @click="changeAsc('comission')"
        cols="2"
        md="1"
        class="d-none d-md-block d-flex pointer mr-4"
      >
        {{ $t('seller.affiliation.meus_afiliados.text_1677') }}
        <svg
          :class="{
            ascendente: tableChange.comission === 'asc',
            descendente: tableChange.comission === 'desc1',
          }"
          class="asc-desc"
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.27656 0.166504C0.849387 0.166504 0.618932 0.667563 0.896935 0.9919L3.80982 4.39027C4.00937 4.62308 4.36953 4.62308 4.56908 4.39027L7.48197 0.991899C7.75997 0.667563 7.52952 0.166504 7.10234 0.166504H1.27656Z"
            fill="#002363"
          />
        </svg>
      </b-col>

      <b-col @click="changeAsc('balance')" class="pointer">
        {{ $t('seller.affiliation.meus_afiliados.text_1678') }}
        <svg
          :class="{
            ascendente: tableChange.balance === 'asc',
            descendente: tableChange.balance === 'desc1',
          }"
          class="asc-desc"
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.27656 0.166504C0.849387 0.166504 0.618932 0.667563 0.896935 0.9919L3.80982 4.39027C4.00937 4.62308 4.36953 4.62308 4.56908 4.39027L7.48197 0.991899C7.75997 0.667563 7.52952 0.166504 7.10234 0.166504H1.27656Z"
            fill="#002363"
          />
        </svg>
      </b-col>
      <b-col cols="3" md="1" class="ml-3 d-md-none d-block"> Ações </b-col>
    </b-row>
    <!-- END HEADER -->

    <!-- BODY -->
    <template v-if="myAffiliates.length > 0 && !loading">
      <b-row class="Table-body" v-for="item in myAffiliates" :key="item.id">
        <b-col cols="0" class="d-none d-md-block" v-if="false == true">
          <b-form-checkbox
            :value="item"
            :id="`checkbox-group-` + item.id"
            :name="`flavour-` + item.id"
            @change="clickCheck(item)"
            v-model="item_check"
            Desabilitar
          ></b-form-checkbox>
        </b-col>
        <b-col
          cols="1"
          class="d-none d-md-block status-item ml-2 mr-5"
          :class="getStatus(item.status).class"
          v-b-tooltip="{
            title: getStatus(item.status).text,
            placement: 'bottom',
          }"
        >
          <span class="d-none d-md-block">{{ item.id }}</span>
        </b-col>
        <b-col cols="4" md="1" class="d-none d-md-block mr-4">
          {{ item.created_at | datetime }}</b-col
        >
        <b-col cols="3" md="2" class="dados-cliente" v-if="item.affiliate">
          <div
            :id="item.affiliate.name"
            v-b-tooltip="{
              title: `ID do Afiliado: ${item.affiliate_id} - ${item.affiliate.name}`,
              placement: 'top',
            }"
          >
            #{{ item.affiliate_id }} -{{
              item.affiliate.name && item.affiliate.name.length > 20
                ? item.affiliate.name.substr(0, 20) + "..."
                : item.affiliate.name
            }}
          </div>
          <div
            :id="item.affiliate.email"
            v-b-tooltip="{
              title: item.affiliate.email,
              placement: 'top',
            }"
          >
            {{
              item.affiliate.email && item.affiliate.email.length > 20
                ? item.affiliate.email.substr(0, 20) + "..."
                : item.affiliate.email
            }}
          </div>
        </b-col>
        <b-col cols="3" md="2" class="dados-cliente" v-if="!item.affiliate">
          <div>Sem dados do afiliado</div>
        </b-col>

        <b-col cols="5" md="2" class="dados-cliente d-none d-md-block">
          <multiselect
            v-model="item.manager"
            id="ajax"
            label="text"
            track-by="text"
            @input="updateCoSeller($event, item)"
            :placeholder="item.manager ? item.manager.name : 'Sem gerente'"
            selectLabel=""
            :custom-label="customLabel"
            deselectLabel=""
            selectedLabel="✔️"
            open-direction="bottom"
            :options="coSellers"
            :multiple="false"
            :searchable="true"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="false"
            :options-limit="10"
            :show-no-results="true"
            :hide-selected="true"
            @search-change="aux_coseller_debounce"
          >
            <span slot="noOptions">{{ $t('seller.affiliation.meus_afiliados.text_1679') }}</span>

            <span slot="noResult">
              {{ $t('seller.affiliation.meus_afiliados.text_1680') }}
            </span>
          </multiselect>
        </b-col>
        <b-col
          class="d-none d-md-block"
          cols="3"
          md="2"
          :id="item.product.name"
          v-b-tooltip="{
            title: item.product.name,
            placement: 'top',
          }"
        >
          {{
            item.product.name && item.product.name.length > 20
              ? item.product.name.substr(0, 20) + "..."
              : item.product.name
          }}
        </b-col>
        <b-col cols="2" md="1" class="mr-3">
          {{ item.comission || item.product.comission }}%</b-col
        >
        <b-col>
          R$ {{ item.balance ? formatMoney(item.balance) : "0,00" }}</b-col
        >

        <b-col cols="3" md="1">
          <div>
            <b-dropdown
              size="sm"
              right
              dropup
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              class="drop-personalizado"
            >
              <template v-slot:button-content>
                <img
                  src="@/assets/img/icons/more2.svg"
                  class="btn-more btn-more2"
                />
              </template>

              <b-dropdown-item
                class="drop-item"
                @click.prevent="alterarComissao(item)"
              >
                {{ $t('seller.affiliation.meus_afiliados.text_1681') }}
              </b-dropdown-item>

              <b-dropdown-item
                class="drop-item"
                v-if="item.status == 'APPROVED'"
                @click.prevent="desabilitarAfiliado(item)"
              >
                {{ $t('seller.affiliation.meus_afiliados.text_1682') }}
              </b-dropdown-item>
              <b-dropdown-item
                class="drop-item"
                v-if="item.status != 'APPROVED'"
                @click.prevent="habilitarAfiliado(item)"
              >
                {{ $t('seller.affiliation.meus_afiliados.text_1683') }}
              </b-dropdown-item>
              <b-dropdown-item
                class="drop-item"
                @click.prevent="deletarAfiliado(item)"
              >
                {{ $t('seller.affiliation.meus_afiliados.text_1684') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
    </template>

    <b-row>
      <b-col>
        <Paginate
          v-if="myAffiliates.length > 0"
          :totalPages="pagination.totalPages"
          :activePage="pagination.currentPage"
          @to-page="toPage"
          @per-page="perPage"
        />
      </b-col>
    </b-row>

    <div
      v-if="loading"
      class="py-5 my-5 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>

    <b-row
      v-if="!loading && myAffiliates.length == 0"
      class="Table-body justify-content-center"
    >
      <p class="nao-encontrado">
        {{ $t('seller.affiliation.meus_afiliados.text_1685') }}
      </p>
    </b-row>

    <!-- END BODY -->

    <ComissionAffiliate :dados="dadosItem" @close="fetchAll" />
    <FiltersModal @filter="filter" />
  </b-container>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import _ from "lodash";
import moment from "moment-timezone";

import DateRangePicker from "vue2-daterange-picker";
import Paginate from "@/components/shared/Paginate";

import Money from "@/mixins/money";
import AffiliateService from "@/services/resources/AffiliateService";
const serviceAffiliate = AffiliateService.build();

import CoSellerService from "@/services/resources/CoSellerService";
const serviceCoSeller = CoSellerService.build();

import ComissionAffiliate from "./ComissionAffiliate.vue";
import FiltersModal from "./modals/filters";

export default {
  name: "MeusAfiliados",
  data() {
    return {
      coSellers: [],
      tableChange: {
        id: "asc",
        date: "desc",
        affiliate: "desc",
        comission: "desc",
        balance: "desc",
      },
      loading: false,
      loading_product: false,
      name_product: null,
      total: 0,
      itens: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      dadosItem: {},
      search: "",
      search2: "",
      item_check: [],
      item_check2: [],
      products: [],
      filters: {},
      myAffiliates: [],
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange: {
        startDate: moment(),
        endDate: moment(),
      },
    };
  },
  mixins: [Money],

  components: {
    Multiselect,
    ComissionAffiliate,
    DateRangePicker,
    Paginate,
    FiltersModal,
  },

  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, today],
        Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo Período": [new Date(2020, 0, 1), new Date(today)],
      };
    },
  },
  methods: {
    aux_coseller_debounce(query) {
      this.search2 = query;
      this.debounce_coseller();
    },
    debounce_coseller: _.debounce(function() {
      this.fetchCoSeller(this.search2);
    }, 500),
    updateCoSeller(dados, gerente) {
      let data = {
        id: `${gerente.id}/manager?manager_id=${dados.id}`,
      };
      serviceAffiliate
        .createId(data)
        .then(() => {
          this.fetchAll();
          this.$bvToast.toast(this.$t('seller.affiliation.meus_afiliados.text_124'), {
            variant: "success",
            title: this.$t('seller.affiliation.meus_afiliados.text_1686'),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    fetchCoSeller(search = null) {
      let data = {
        url: `/all`,
      };
      if (search) data.search = search;

      this.coSellers = [];

      serviceCoSeller
        .search(data)
        .then((resp) => {
          console.log(resp);
          // if(!resp.length){
          //   this.coSellers.push({ value: null, text: 'Sem Gerentes' });
          //   return
          // }
          // resp.forEach((element) => {
          //   this.coSellers.push({ value: element.id, text: element.name });
          // });
          if (resp.length) {
            this.coSellers = resp;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    customLabel({ name }) {
      return `${name}`;
    },
    changeAsc(type) {
      if (type === "id") {
        if (this.tableChange.id === "asc") {
          this.tableChange = {
            id: "desc1",
            created_at: "desc",
            affiliate: "desc",
            comission: "desc",
            balance: "desc",
          };
        } else {
          this.tableChange = {
            id: "asc",
            created_at: "desc",
            affiliate: "desc",
            comission: "desc",
            balance: "desc",
          };
        }
      } else if (type === "created_at") {
        if (this.tableChange.created_at === "asc") {
          this.tableChange = {
            id: "desc",
            created_at: "desc1",
            affiliate: "desc",
            comission: "desc",
            balance: "desc",
          };
        } else {
          this.tableChange = {
            id: "desc",
            created_at: "asc",
            affiliate: "desc",
            comission: "desc",
            balance: "desc",
          };
        }
      } else if (type === "affiliate") {
        if (this.tableChange.affiliate === "asc") {
          this.tableChange = {
            id: "desc",
            created_at: "desc",
            affiliate: "desc1",
            comission: "desc",
            balance: "desc",
          };
        } else {
          this.tableChange = {
            id: "desc",
            created_at: "desc",
            affiliate: "asc",
            comission: "desc",
            balance: "desc",
          };
        }
      } else if (type === "comission") {
        if (this.tableChange.comission === "asc") {
          this.tableChange = {
            id: "desc",
            created_at: "desc",
            affiliate: "desc",
            comission: "desc1",
            balance: "desc",
          };
        } else {
          this.tableChange = {
            id: "desc",
            created_at: "desc",
            affiliate: "desc",
            comission: "asc",
            balance: "desc",
          };
        }
      } else if (type === "balance") {
        if (this.tableChange.balance === "asc") {
          this.tableChange = {
            id: "desc",
            created_at: "desc",
            affiliate: "desc",
            comission: "desc",
            balance: "desc1",
          };
        } else {
          this.tableChange = {
            id: "desc",
            created_at: "desc",
            affiliate: "desc",
            comission: "desc",
            balance: "asc",
          };
        }
      }
      this.fetchAll();
    },
    removeFilterProduct() {
      this.name_product = null;
      this.fetchAll();
    },
    openFilters() {
      this.$bvModal.show("my-affiliates-filters");
    },

    debounce: _.debounce(function() {
      this.fetchAll();
    }, 500),
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchAll();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchAll();
    },
    filter(filters) {
      this.name_product = null;
      this.filters = filters;
      this.pagination.currentPage = 1;
      this.fetchAll();
    },
    fetchAll(filter_product = []) {
          this.$router.replace({ query: { start_date: moment(this.dateRange.startDate).format("MM-DD-YYYY"), end_date: moment(this.dateRange.endDate).format("MM-DD-YYYY"), ...this.filters } }).catch(() => {});

      this.loading = true;
      this.myAffiliates = [];

      let data = {
        type: "all",
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };

      var order = this.tableChange;
      var direction_order = "";
      var column_order = "";
      for (var indice in order) {
        if (order[indice] === "asc") {
          column_order = indice;
          direction_order = "asc";
        } else if (order[indice] === "desc1") {
          column_order = indice;
          direction_order = "desc";
        }
      }

      data.column_order = column_order;
      data.direction_order = direction_order;

      if (filter_product.length) {
        data.products = filter_product;
      }

      if (
        this.search != null &&
        typeof this.search == "string" &&
        this.search.length > 0
      ) {
        data.search = this.search;
      }

      if (this.dateRange.startDate != null && this.dateRange.endDate != null) {
        data.date_start = moment(this.dateRange.startDate, "MM-DD-YYYY")
          .tz("America/Sao_Paulo")
          .format("YYYY-MM-DDT00:00:00-03:00");
        data.date_end = moment(this.dateRange.endDate, "MM-DD-YYYY")
          .tz("America/Sao_Paulo")
          .format("YYYY-MM-DDT23:59:59-03:00");
      }

      Object.keys(this.filters).forEach(
        (key) =>
          (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      );
      data = Object.assign(data, this.filters);

      serviceAffiliate
        .search(data)
        .then((response) => {
          this.myAffiliates = response.data;
          this.pagination.totalPages = response.last_page;
          this.itens = response.total;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status) {
          case "APPROVED": {
            r = { text: "Aprovado", class: "is-green" };
            break;
          }
          case "DISABLED": {
            r = { text: "Desabilitado", class: "is-red" };
            break;
          }
          case "REJECTED": {
            r = { text: "Rejeitado", class: "is-red" };
            break;
          }
          case "REQUESTED": {
            r = { text: "Aguardando", class: "is-warning" };
            break;
          }
        }
        return r;
      } else return { text: status, class: "is-black" };
    },
    clickCheckAll(event) {
      if (!event.length) {
        this.item_check = [];
        this.item_check2 = [];
        return;
      }

      this.myAffiliates.forEach((item) => {
        this.item_check.push(item);
      });
    },
    clickCheck() {
      this.item_check2 = [];
    },
    // meus afiliados
    alterarComissao(item) {
      if (item.affiliate_id) {
        this.dadosItem = item;
        this.$bvModal.show("comission-affiliate");
      } else {
        this.$bvToast.toast("Dados do afiliado indisponível", {
          title: "Comissão Afiliado",
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    deletarAfiliado(item) {
      // let text = selected
      //   ? `Deseja desabilitar os afiliados selecionados? [${this.item_check.length}]`
      //   : `Deseja desabilitar o Afiliado ${item.affiliate.name}`;
      Vue.swal({
        title: "Deletar Afiliado",
        text: `Deseja deletar permanentemente o Afiliado ${
          item.affiliate ? item.affiliate.name : ""
        }`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removeAfiliado(item);
        }
      });
    },
    removeAfiliado(item) {
      this.loading = true;
      serviceAffiliate
        .destroy(item.id)
        .then((response) => {
          if (response.success) {
            this.$bvToast.toast(`Afiliado deletado com sucesso`, {
              title: "Afiliados",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
          this.fetchAll();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    desabilitarAfiliado(item) {
      // let text = selected
      //   ? `Deseja desabilitar os afiliados selecionados? [${this.item_check.length}]`
      //   : `Deseja desabilitar o Afiliado ${item.affiliate.name}`;
      Vue.swal({
        title: "Desabilitar Afiliado",
        text: `Deseja desabilitar o Afiliado ${
          item.affiliate ? item.affiliate.name : ""
        }`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Desabilitar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.statusAfiliado(item, "DISABLED");
        }
      });
    },

    habilitarAfiliado(item) {
      // let text = selected
      //   ? `Deseja desabilitar os afiliados selecionados? [${this.item_check.length}]`
      //   : `Deseja desabilitar o Afiliado ${item.affiliate.name}`;
      Vue.swal({
        title: "Habilitar Afiliado",
        text: `Deseja habilitar o Afiliado ${
          item.affiliate ? item.affiliate.name : ""
        }`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Habilitar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-info2 mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.statusAfiliado(item, "APPROVED");
        }
      });
    },
    statusAfiliado(item, status) {
      this.loading = true;

      let data = {
        ids: [item.id],
        status: status,
      };
      serviceAffiliate
        .update(data, item.id)
        .then((response) => {
          if (response.success) {
            this.$bvToast.toast(
              `Afiliação ${
                status == "DISABLED" ? "desabilitada" : "habilitada"
              } com sucesso`,
              {
                title: "Afiliados",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
          }
          this.fetchAll();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.dateRange.startDate = this.$route.query.start_date || moment();
      this.dateRange.endDate = this.$route.query.end_date || moment();
      this.filters.status = this.$route.query.status || "";
    this.filters.products = this.$route.query.products || "";
    if (typeof this.$route.query.p_id != "undefined") {
      const product = [this.$route.query.p_id];
      this.fetchAll(product);
      this.name_product = this.$route.query.p_name;
    } else {
      this.fetchAll();
    }
  },
};
</script>

<style>
.afiliados .multiselect__tags {
  border: none !important;
  overflow: hidden !important;
  padding: 16px 0px !important;
  background: transparent !important;
  cursor: pointer !important;
}

.afiliados .multiselect__content {
  border: none !important;
  border-radius: 0 0 3px 3px;
  background: rgb(239, 239, 239);
}

.afiliados span.multiselect__placeholder {
  font-weight: 100 !important;
  color: #333 !important;
}
</style>

<style scoped>
produto-item {
  transition: 0.3s;
}
@media screen and (max-width: 768px) {
  h5 {
    font-size: 16px;
    margin-left: 20px;
  }
  .produto-item + .produto-item {
    margin-top: 10px;
  }
}
.btn-more {
  background: rgba(0, 35, 99, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 19px 15px;
  border-radius: 5px;
}

.Table-body {
  /* padding: 15px 20px !important;
   */
  padding: 15px;
}

.btn-more2 {
  background: rgba(33, 51, 210, 0.1);
  outline: none;
}
span.btn-more2 {
  font-weight: 600;
  padding: 15px 30px;
}
.btn-more2.ALL {
  background: rgba(33, 51, 210, 0.1);
  color: #2a63ab;
}
.btn-more2.APPROVED {
  color: #002363;
  background: rgba(0, 33, 99, 0.1);
}
.btn-more2.DISABLED {
  color: #ff0c37;
  background: rgba(255, 12, 55, 0.1);
}

.enviar-msg {
  font-size: 14px;
  font-weight: 600;
  color: #2a63ab;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 300px;
  /* background: red; */
}
.enviar-msg:disabled,
.enviar-msg.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.select-all {
  font-size: 14px;
  color: #81858e;
  font-weight: 600;
  display: inline-block;
  margin: 15px 0;
}

.status-item:before {
  left: 0 !important;
  top: 8px !important;
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 33, 99, 0.1);
  margin-right: 20px;
}
.btn-table:first-child:active {
  background: rgba(0, 33, 99, 0.1);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}

.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}
.datas .vue-daterange-picker {
  width: 190px;
  font-size: 14px !important;
  color: #81858e !important;
  text-align: center;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 350px;
  height: 60px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 35px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.filtro-produto {
  margin-left: 20px;
}
.filtro-produto p {
  margin: 0;
  font-size: 14px;
  color: #81858e;
}
.filtro-produto p a {
  font-weight: 600;
  color: #2a63ab;
  font-size: 14px;
  margin-left: 5px;
}
.total-vendas.info {
  justify-content: flex-end;
  margin-right: 30px;
}

.asc-desc {
  filter: grayscale(1);
}

.pointer {
  cursor: pointer;
  white-space: nowrap;
}

.ascendente {
  transform: rotate(180deg);
  filter: grayscale(0);
}
.descendente {
  transform: rotate(0deg);
  filter: grayscale(0);
}

.text-center {
  text-align: center;
}
</style>
