<template>
  <BaseModal name="tome-filters" size="lg" title="Filtros">
    <b-row>
      <b-col cols="12">
        <b-form novalidate>
          <b-row>
            <b-col>
              <b-form-group label="Produtos">
                <multiselect
                  v-model="filter.products"
                  id="ajax"
                  label="name"
                  track-by="id"
                  placeholder="Pesquise o Produto"
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="products"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search-change="aux_product_debounce"
                >
                  <span slot="noOptions">Pesquise pelo produto</span>

                  <span slot="noResult"
                    >Oops! Nenhum produto encontrado. Pesquise outro termo</span
                  >
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Status"
                label-for="status"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('status')"
                  class="btn-todos"
                  >Todos os status</a
                >
                <multiselect
                  v-model="filter.status"
                  placeholder="Status "
                  label="name"
                  track-by="id"
                  :options="status_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  selectedLabel="✔️"
                  ><span slot="noResult"
                    >Nenhum status encontrado.</span
                  ></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="secondary_outline" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="secondary" @click="onSubmit"> Filtrar </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import _ from "lodash";

import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

export default {
  props: {
    value: {
      type: Number,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      search: "",
      loading: false,

      filter: {
        status: [],
        products: [],
      },
      products: [],

      status_list: [
        {
          id: "APPROVED",
          name: "Aprovado",
        },
        {
          id: "DISABLED",
          name: "Desabilitado",
        },
        {
          id: "REJECTED",
          name: "Rejeitado",
        },
        {
          id: "REQUESTED",
          name: "Aguardando",
        },
      ],
    };
  },

  methods: {
    selecionarTodos(type) {
      switch (type) {
        case "status": {
          this.filter.status = [
            {
              id: "APPROVED",
              name: "Aprovado",
            },
            {
              id: "DISABLED",
              name: "Desabilitado",
            },
            {
              id: "REJECTED",
              name: "Rejeitado",
            },
            {
              id: "REQUESTED",
              name: "Aguardando",
            },
          ];
          break;
        }
      }
    },
    // produto
    aux_product_debounce(query) {
      this.loading = true;
      this.search = query;
      this.debounce_product();
    },
    debounce_product: _.debounce(function() {
      this.fetchProducts(this.search);
    }, 500),

    onSubmit() {
      let data = {
        status: this.filter.status.map((item) => item.id),
      };
      if (this.filter.products.length) {
        data.products = this.filter.products.map((item) => item.id);
      }

      this.$emit("filter", data);
      this.$bvModal.hide("tome-filters");
    },
    fetchProducts(search = null) {
      let data = {
        list: true,
        affiliates: true,
      };

      if (search) data.search = search;

      this.products = [];

      serviceProduct
        .search(data)
        .then((response) => {
          response.forEach((element) => {
            this.products.push({ id: element.id, name: element.name });
          });
        })
        .catch((err) => {
          console.log(err);
          this.products = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-filter {
    display: block;
  }
  .top-filter #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
