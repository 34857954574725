<template>
  <BaseModal
    name="affiliate-pixels"
    size="md"
    title="Pixels"
    @shown="fetchPixels"
    @hidden="reset"
  >
    <b-row>
      <b-col class="container-pixels" v-if="!loading_pixel" v-show="!loading">
        <div class="d-flex justify-content-between align-content-center mb-3">
          <span></span>
          <BaseButton
            variant="secondary_outline"
            id="pixels"
            class="btn-pixel"
            v-if="!novoPixel"
            @click="novoPixel = true"
            >Novo Pixel</BaseButton
          >
        </div>
        <b-form data-anima="top" class="mb-5" v-if="novoPixel">
          <b-col cols="12">
            <b-form-group label="Tipo" label-for="type_pixel">
              <b-form-select
                id="type_pixel"
                name="type_pixel"
                v-model="itemPixels.type"
                @change="changePixelType"
                placeholder="Selecione o tipo"
                :options="typesPixels"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <div class="d-flex justify-content-end">
            <BaseButton
              variant="secondary_outline"
              id="pixels"
              class="btn-pixel"
              @click="cancelPixel"
              >Cancelar</BaseButton
            >
          </div>
        </b-form>
        <div v-if="pixels.length">
          <div v-for="(pixel, index) in pixels" :key="pixel.id" class="pixel">
            <div>
              <img
                class="iconePixel"
                :src="iconPixel(pixel.type)"
                :alt="pixel.type"
              />
              <div>
                <img
                  src="@/assets/img/icons/editar.svg"
                  class="icon-lixeira lixeira-pixel mr-2"
                  :id="'btn-edit-' + pixel.id"
                  @click="editPixelFb(pixel)"
                />
                <b-tooltip
                  :target="'btn-edit-' + pixel.id"
                  title="Editar"
                  placement="left"
                />
                <img
                  src="@/assets/img/icons/lixeira.svg"
                  class="icon-lixeira lixeira-pixel"
                  :target="'btn-remove-' + pixel.id"
                  @click="
                    removePixels(index, pixel.pixel_id, pixel.type, pixel.id)
                  "
                />
                <b-tooltip :target="'btn-remove-' + pixel.id" title="Remover" />
              </div>
            </div>
            <div>
              <div class="pixel-type">
                <p><b>ID Pixel: </b> {{ pixel.pixel_id }}</p>
                <p class="mt-2" v-if="pixel.domain">
                  <b>Domínio: </b>{{ pixel.domain.domain }}
                </p>
              </div>
              <p>{{ pixel.updated_at | datetime }}</p>
            </div>
            <div class="pixel-status mt-3" id="pixel-status">
              <span
                class="status-pixel"
                id="cname"
                :class="{ ativada: pixels[index].dns }"
              >
                CNAME
                {{
                  pixels[index].dns ? "verificado" : "aguardando verificação"
                }}
              </span>
              <b-tooltip
                v-if="!pixels[index].dns"
                target="cname"
                title="Pixel funcionando com o CNAME em análise"
              />

              <span
                id="ssl"
                class="status-pixel"
                :class="{ ativada: pixels[index].ssl }"
              >
                Certificado
                {{
                  pixels[index].ssl ? "verificado" : "aguardando verificação"
                }}
              </span>
              <b-tooltip
                v-if="!pixels[index].ssl"
                target="ssl"
                title="Pixel funcionando com o Certificado em análise"
              />
              <span
                class="status-pixel"
                :class="{ ativada: pixel.optimization }"
              >
                Otimização
                {{ pixel.optimization ? "ativada" : "desativada" }}
              </span>
              <b-tooltip
                v-if="!pixel.optimization"
                target="otimizacao"
                :title="
                  `Pixel funcionando com a otimização ${
                    pixel.optimization ? 'ativada' : 'desativada'
                  }`
                "
              />
            </div>
          </div>
        </div>
        <div v-else>
          <p class="nao-encontrado">Nenhum Pixel cadastrados</p>
        </div>
      </b-col>
    </b-row>

    <PixelFb
      @close="atualizaListaPixel"
      :product_id="product_id"
      :pixel_edit="pixel_edit"
      :type_pixel="pixel_type"
    />

    <template v-slot:footer="{}">
      <span></span>
    </template>
  </BaseModal>
</template>
<script>
import Utils from "@/mixins/utils";
import DomainPixelService from "@/services/resources/DomainPixelService";
const serviceDomain = DomainPixelService.build();

import ConversionPixelService from "@/services/resources/ConversionPixelService";
const serviceConversionPixel = ConversionPixelService.build();

import GetPixelService from "@/services/resources/GetPixelService";
const serviceGetPixel = GetPixelService.build();

import PixelFb from "@/components/Seller/Products/modals/PixelFb";

import Vue from "vue";

export default {
  props: ["product_id"],
  mixins: [Utils],
  components: {
    PixelFb,
  },
  data() {
    return {
      loading: false,
      submit: false,
      pixels: [],
      loading_pixel: false,
      pixel_type: "",
      novoPixel: false,
      pixel_edit: {
        item: {},
        edit: false,
      },
      itemPixels: {
        pixel_id: "",
        type: "",
      },
      typesPixels: [
        { value: null, text: "Selecione o Tipo", disabled: true },
        { value: "FACEBOOK", text: "Facebook" },
        { value: "GOOGLEADWORDS", text: "Google AdWords" },
        { value: "GOOGLETAGMANAGER", text: "Google Tag Manager" },
        { value: "GOOGLEANALYTICS", text: "Google Analytics" },
        { value: "TIKTOK", text: "TikTok" },
      ],
    };
  },
  methods: {
    iconPixel(type) {
      return require(`@/assets/img/${type.toLowerCase()}_icone.png`);
    },
    reset() {
      this.pixels = [];
    },
    openModal(modal) {
      this.$bvModal.show(modal);
    },
    atualizaListaPixel(edit) {
      this.itemPixels = {
        pixel_id: "",
        type: null,
      };
      if (edit) {
        this.pixels = [];
        this.fetchPixels();
      }
    },
    fetchPixels() {
      this.loading = false;
      if (!this.pixels.length) {
        let data = {
          id: this.product_id + "?a_id=" + this.userID,
        };
        serviceGetPixel
          .read(data)
          .then((response) => {
            if (response.length) {
              this.pixels = response.reverse().map((item) => ({
                ...item,
                ssl: 0,
                dns: 0,
              }));

              this.pixel_edit = {
                item: {},
                edit: false,
              };
              this.pixels.forEach((item, index) => {
                this.validaDomainPixel(item, index);
              });
            } else {
              this.pixels = [];
              this.loading_pixel = false;
              this.loading = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    validaDomainPixel(pixel, index) {
      this.loading_pixel = true;

      let data = {
        domain: pixel.domain ? pixel.domain.domain : "",
      };

      if (data.domain) {
        serviceDomain
          .search(data)
          .then((response) => {
            pixel.dns = response.dns;
            pixel.ssl = response.ssl;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading_pixel = false;
          });
      } else {
        this.loading_pixel = false;
      }
    },
    changePixelType(e) {
      this.novoPixel = false;
      this.pixel_edit = {
        item: {},
        edit: false,
      };
      this.pixel_type = e;
      this.openModal("pixel-fb");
      this.itemPixels.type = null;
    },
    cancelPixel() {
      this.novoPixel = false;
      this.itemPixels = {
        pixel_id: "",
        type: null,
      };
    },
    addPixels() {
      if (this.itemPixels.id) {
        this.atualizaPixel();
      } else {
        this.createPixel();
      }
    },
    createPixel() {
      this.loading_pixel = true;

      let data = {
        pixel_id: this.itemPixels.pixel_id,
        product_id: this.product_id,
        type: this.itemPixels.type,
      };
      serviceConversionPixel
        .create(data)
        .then((response) => {
          this.$bvToast.toast("Pixel adicionado com sucesso", {
            title: "Pixel",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.pixels.unshift(response.pixel);

          this.itemPixels = {
            pixel_id: "",
            type: null,
          };
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading_pixel = false;
        });
    },
    atualizaPixel() {
      this.loading_pixel = true;

      let data = {
        id: this.itemPixels.id,
        pixel_id: this.itemPixels.pixel_id,
        product_id: this.product_id,
        type: this.itemPixels.type,
      };
      serviceConversionPixel
        .update(data)
        .then(() => {
          this.$bvToast.toast("Pixel atualizado com sucesso", {
            title: "Pixel",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });

          this.itemPixels = {
            pixel_id: "",
            type: null,
          };
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading_pixel = false;
        });
    },
    editPixelFb(pixel) {
      this.pixel_type = pixel.type;
      this.pixel_edit = {
        item: pixel,
        edit: true,
      };
      this.openModal("pixel-fb");
    },
    removePixelItem(id_pixel) {
      this.loading_pixel = true;
      let data = {
        id: id_pixel,
      };
      serviceConversionPixel
        .destroy(data)
        .then(() => {
          this.$bvToast.toast("Pixel removido com sucesso", {
            title: "Pixel",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading_pixel = false;
        });
    },

    removePixels(index_lista, pixel_id, type, id_pixel) {
      Vue.swal({
        title: "Remover Pixel ",
        text: `Deseja continuar removendo o pixel ${pixel_id}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // atualizando front
          this.pixels.splice(index_lista, 1);
          this.removePixelItem(id_pixel);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-remove {
  // margin-top: 30px;
}
.nova-meta {
  font-size: 13px;
  padding: 10px 20px;
  margin: 20px 0;
  margin-top: 0;
  display: flex;
  align-items: center;
}
.btn-remover {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0 15px !important;
  height: 40px;
  margin-top: 25px;
  margin-left: 10px;
  margin-left: auto;
}
.btn-remover img {
  width: 15px;
  margin-right: 5px;
  filter: brightness(0) invert(1);
  transform: rotate(45deg);
}
// pixel
.container-pixels {
  max-height: 400px;
  overflow: auto;
  padding: 10px;
}
.pixel {
  padding: 15px;
  border: 1px solid #ededf0;
  border-radius: 5px;
}
.pixel + .pixel {
  margin-top: 10px;
}
.pixel .iconePixel {
  margin: 15px 0 20px 0;
  max-width: 170px;
}
.pixel span {
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}
.pixel div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pixel div.pixel-type {
  display: block;
}
.pixel-status {
  flex-wrap: wrap;
  justify-content: flex-start !important;
  gap: 10px;
}
.status-pixel {
  font-size: 11px !important;
  font-weight: normal !important;
  background: #f7f7f7;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 !important;
}
.status-pixel.ativada {
  background: #00236320;
  color: #002363;
}
.pixel p {
  font-size: 13px;
}
input#pixel_id,
select#type_pixel,
.btn-pixel {
  padding: 0 20px !important;
  height: 40px !important;
  font-size: 12px;
}

.icon-lixeira,
.icon-editar {
  width: 14px;
  filter: invert(50%);
  cursor: pointer;
}
.icon-editar {
  margin-right: 15px !important;
}
.opcoes-entrega li:hover {
  font-weight: 600;
  background: #f7f7f7;
}

.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}
</style>
